import AlgoliaBrandsTransformer from '@/modules/api/transformers/algolia-brands-transformer'
import AlgoliaCategoriesTransformer from '@/modules/api/transformers/algolia-categories-transformer'
import AlgoliaVariantsTransformer from '@/modules/api/transformers/algolia-variants-transformer'
import SearchBrandsTransformer from '@/modules/api/transformers/search-brands-transformer'
import SearchCategoriesTransformer from '@/modules/api/transformers/search-categories-transformer'
import { isEarlyAccessPlpFlagOn } from '@/modules/services/utilities'

const state = {
  searchBrands: [],
  searchCategories: [],
  searchProducts: [],
  searchQuery: '',
  recentSearches: [],
  recentProducts: [],
  popularQueries: [],
  focusSearch: false,
  isSearchingProducts: false,
  isSearchingOthers: false,
  pageSize: 4,
  isResultLoaded: false
}

const getters = {
  searchResultsFound: (state) => {
    return state.searchBrands.length > 0 || state.searchCategories.length > 0 || state.searchProducts.length > 0
  }
}

const mutations = {
  setSearchBrands (state, brands) {
    state.searchBrands = brands
  },

  setSearchCategories (state, categories) {
    state.searchCategories = categories
  },

  setSearchProducts (state, products) {
    state.searchProducts = products
  },

  setIsSearchingOthers (state, bool) {
    state.isSearchingOthers = bool
  },

  setIsSearchingProducts (state, bool) {
    state.isSearchingProducts = bool
  },

  setSearchQuery (state, text) {
    state.searchQuery = text
  },

  setPopularQueries (state, queries) {
    state.popularQueries = queries
  },

  setFocusSearch (state, bool) {
    state.focusSearch = bool
  },

  setRecentSearches (state, array) {
    state.recentSearches = array
  },

  setRecentProducts (state, array) {
    state.recentProducts = array
  },

  setIsResultLoaded (state, bool) {
    state.isResultLoaded = bool
  }
}

const actions = {
  async fetchAlgoliaSearchResults ({ commit, state }) {
    const encodedQuery = encodeURIComponent(state.searchQuery.trim())
    const version = await isEarlyAccessPlpFlagOn() ? '3.1' : '3'
    const url = `/v${version}/search?query=${encodedQuery}&include=brands,categories&page[size]=${state.pageSize}`

    return window.axios.get(url).then((response) => {
      const brandData = response.data.results.brands.hits
      const categoryData = response.data.results.categories.hits
      const variantData = response.data.results.variants.hits

      // commit transformed categories and brands
      commit('setSearchBrands', new AlgoliaBrandsTransformer(brandData).transformedData)
      commit('setSearchCategories', new AlgoliaCategoriesTransformer(categoryData).transformedData)
      commit('setIsSearchingOthers', false)
      // commit transformed products (from variants)
      commit('setSearchProducts', new AlgoliaVariantsTransformer(variantData).transformedData)
      commit('setIsSearchingProducts', false)
      commit('setIsResultLoaded', true)
    }).catch(() => {})
  },

  async fetchSearchResults ({ commit, state }) {
    const encodedQuery = encodeURIComponent(state.searchQuery.trim())
    const url = `/api/v2.3/search?query=${encodedQuery}&from=web_search_bar&include=brands,categories`

    return window.axios.get(url).then((response) => {
      commit('setSearchBrands', new SearchBrandsTransformer(response.data.data.relationships.brands.data, response.data.included).transformedData)
      commit('setSearchCategories', new SearchCategoriesTransformer(response.data.data.relationships.categories.data, response.data.included).transformedData)
      commit('setIsSearchingOthers', false)
      commit('setIsResultLoaded', true)
    }).catch(() => {})
  },

  fetchSearchProducts ({ commit, state }) {
    const encodedQuery = encodeURIComponent(state.searchQuery.trim())
    const url = `/api/v2.3/search/products?query=${encodedQuery}&page[size]=${state.pageSize}`

    return window.axios.get(url).then((response) => {
      commit('setSearchProducts', response.data.data)
      commit('setIsSearchingProducts', false)
      commit('setIsResultLoaded', true)
    }).catch(() => {})
  },

  fetchPopularQueries ({ commit }) {
    const url = '/api/v2.3/search/popular_queries'

    return window.axios.get(url).then((response) => {
      commit('setPopularQueries', response.data.data)
    }).catch(() => {})
  },

  addRecentSearch ({ commit, state, rootGetters }, { query, type }) {
    const currentRecentSearch = state.recentSearches.concat()

    if (!query) { return } // When query object is not found. For example, when a brand is discontinued

    let queryName = query.name
    let querySlug = query.slug

    if (type) {
      // when one of the search suggestions is selected
      queryName = queryName || query.attributes.name || query.attributes.label
      querySlug = querySlug || `/${type}/${query.attributes['slug-url']}?q=${state.searchQuery}`

      // customize name if search query is a product
      if (type === 'products') {
        queryName = [query.attributes['brand-name'], queryName].join(', ')
      }
    } else {
      // when user search by keyword
      queryName = queryName || query
      querySlug = querySlug || `/search?q=${query}`
    }

    if (currentRecentSearch.length > 0) {
      const recentSearchIndex = currentRecentSearch.findIndex(s => s.name.toLowerCase() === queryName.toLowerCase())
      if (recentSearchIndex !== -1) {
        currentRecentSearch.splice(recentSearchIndex, 1)
      }
    }

    currentRecentSearch.unshift({ name: queryName, slug: querySlug, type })

    if (currentRecentSearch.length > 5) {
      currentRecentSearch.pop()
    }

    try {
      localStorage.setItem('recentSearches', JSON.stringify(currentRecentSearch))
    } catch (error) {
      commit('setRecentSearches', currentRecentSearch)
    }
  },

  addRecentProduct ({ commit, state }, product) {
    let currentRecentProducts = []

    // when navigating from a non-spa
    try {
      const recentProducts = JSON.parse(localStorage.getItem('recentProducts'))
      if (recentProducts !== null) {
        currentRecentProducts = recentProducts.concat()
      }
    } catch (error) {}

    if (currentRecentProducts.length > 0) {
      const recentProductIndex = currentRecentProducts.findIndex(s => s.id === product.id)
      if (recentProductIndex !== -1) {
        currentRecentProducts.splice(recentProductIndex, 1)
      }
    }

    currentRecentProducts.unshift(product)

    if (currentRecentProducts.length > 4) {
      currentRecentProducts.pop()
    }

    try {
      localStorage.setItem('recentProducts', JSON.stringify(currentRecentProducts))
    } catch (error) {
      commit('setRecentProducts', currentRecentProducts)
    }
  },

  clearRecentSearches ({ commit }) {
    commit('setRecentSearches', [])
    try {
      localStorage.removeItem('recentSearches')
    } catch (error) {}
  },

  clearRecentProducts ({ commit }) {
    commit('setRecentProducts', [])
    try {
      localStorage.removeItem('recentProducts')
    } catch (error) {}
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
